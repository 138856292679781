<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col-12 col-xl-3 order-xl-2 mb-24 sticky">
        <v-card class="overflow-hidden sticky">
          <l-widget>
            <template #header>
              <w-timer
                :start="widget.timer.start"
                :end="widget.timer.end"
                :server-time="widget.timer.current"
              />
            </template>

            <template #default>
              <w-progress
                :progress="widget.progress"
              />
            </template>
          </l-widget>
        </v-card>
      </div>

      <div class="col-12 col-xl-9 order-xl-1">
        <v-card class="py-32 px-16 p-xl-32">
          <div
            v-show="showTextHint"
            class="row"
          >
            <div class="col">
              <p>
                Без конденсатора невозможна работа практически ни одной электрической цепи или отдельного
                функционального
                блока в приборе. В преобразователях напряжения, согласующих, преобразующих, передающих и усилительных
                цепях
                – везде используются конденсаторы. Даже цифровая электроника не может работать без них.
              </p>

              <p>
                <b>
                  Выберите именно 1 наиболее важный и 1 наименее важный для Вас фактор.
                </b>
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <v-button
                link
                @click="showTextHint = !showTextHint"
              >
                {{ showTextHint ? 'Свернуть' : 'Показать' }} текстовое задание

                <v-icon-svg
                  class="a-flip-y ml-8"
                  :class="{'a-flip-y_active': showTextHint}"
                  fill="none"
                  stroke="currentColor"
                  view-box="0 0 11 5"
                  title="svg-icon-chevron"
                  width=".75rem"
                  height=".75rem"
                >
                  <svg-icon-chevron />
                </v-icon-svg>
              </v-button>
            </div>
          </div>

          <div class="row mt-32">
            <div class="col">
              <h4>4 колонки</h4>

              <table-radio
                ref="radioTable"
                :variant-row="variantRow"
                :variant-col="variantCol"
                unique
              >
                <template slot="th-first">Факторы</template>
              </table-radio>
            </div>
          </div>

          <div class="row mt-32">
            <div class="col">
              <h4>2 колонки</h4>

              <table-radio
                ref="radioTable2"
                :variant-row="variantRow2"
                :variant-col="variantCol2"
                unique
              >
                <template slot="th-first">Факторы</template>
              </table-radio>
            </div>
          </div>

          <div class="row mt-32">
            <div class="col">
              <h4>3 колонки</h4>

              <table-radio
                ref="radioTable3"
                :variant-row="variantRow3"
                :variant-col="variantCol3"
                unique
              >
                <template slot="th-first">Факторы</template>
              </table-radio>
            </div>
          </div>
          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { VButton, VCard, VIconSvg } from '@components/base'
import { WTimer, WProgress } from '@components/widgets'
import { TableRadio } from '@components/features'
import SvgIconChevron from '@components/icons/SvgIconChevron'
import { LWidget } from '@/layouts'

export default {
  name: 'SMostleast',

  components: {
    LWidget,
    SvgIconChevron,
    TableRadio,
    VButton,
    VCard,
    VIconSvg,
    WProgress,
    WTimer
  },

  data () {
    return {
      widget: {
        progress: {
          total: 3,
          current: 1
        },

        timer: {
          start: Math.floor(+new Date() / 1000),
          end: Math.floor(+new Date() / 1000) + 120
        }
      },

      variantRow: [
        {
          id: '3',
          text: ' Я берусь за выполнение сложных рабочих задач',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '4',
          text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad adipisci alias asperiores eaque eos itaque iure iusto laborum maxime, molestias, necessitatibus nostrum quis quisquam quos rerum sequi tempora, velit.',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '1',
          text: ' Я забочусь о чувствах других людей на своей работе',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        }
      ],

      variantCol: [
        {
          id: '1',
          text: '  Наименее подходит',
          media: '',
          group: '',
          sort: '1',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: '  Менее подходит',
          media: '',
          group: '',
          sort: '2',
          attribute: '',
          isActive: false
        },
        {
          id: '3',
          text: '  Более подходит',
          media: '',
          group: '',
          sort: '3',
          attribute: '',
          isActive: false
        },
        {
          id: '4',
          text: '  Наиболее подходит',
          media: '',
          group: '',
          sort: '4',
          attribute: '',
          isActive: false
        }
      ],

      variantRow2: [
        {
          id: '3',
          text: ' Я берусь за выполнение сложных рабочих задач',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '4',
          text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: ' Я берусь за задачи, в которых могу научиться новому',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '1',
          text: ' Я забочусь о чувствах других людей на своей работе',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        }
      ],

      variantCol2: [
        {
          id: '1',
          text: 'Подходит',
          media: '',
          group: '',
          sort: '1',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: 'Неподходит',
          media: '',
          group: '',
          sort: '2',
          attribute: '',
          isActive: false
        }
      ],

      variantRow3: [
        {
          id: '5',
          text: 'Какое-то значение',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '3',
          text: ' Я берусь за выполнение сложных рабочих задач',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '4',
          text: ' Я заряжаюсь энергией, обдумывая свои долгосрочные цели',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: ' Я берусь за задачи, в которых могу научиться новому',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        },
        {
          id: '1',
          text: ' Я забочусь о чувствах других людей на своей работе',
          media: '',
          group: '',
          sort: '99999',
          attribute: '',
          isActive: false
        }
      ],

      variantCol3: [
        {
          id: '1',
          text: 'Подходит',
          media: '',
          group: '',
          sort: '1',
          attribute: '',
          isActive: false
        },
        {
          id: '2',
          text: 'Неподходит',
          media: '',
          group: '',
          sort: '2',
          attribute: '',
          isActive: false
        },
        {
          id: '3',
          text: '3 значение',
          media: '',
          group: '',
          sort: '2',
          attribute: '',
          isActive: false
        }
      ],

      showTextHint: true
    }
  },

  methods: {
    onClick () {
      console.log(this.$refs.radioTable.getData())
      console.log(this.$refs.radioTable2.getData())
      console.log(this.$refs.radioTable3.getData())
    }
  }
}
</script>
